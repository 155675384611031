@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

    :root {
        --navbar-width: 96px;
        --header-pc-height: 66px;

        --swiper-pagination-bullet-horizontal-gap: 5px;
        --swiper-navigation-size: 16px;
        --swiper-navigation-button: 32px;
    }

    * {
        @apply m-0 p-0 box-border;
    }

    body {
        @apply font-primary overflow-overlay;
    }

    .overflow-overlay {
        overflow: overlay;
    }

    // Scrollbar
    html ::-webkit-scrollbar {
        @apply rounded-none h-2 w-2;
    }
    html ::-webkit-scrollbar-thumb {
        @apply bg-[#d6d6d6] rounded;
    }
    html ::-webkit-scrollbar-track {
        @apply bg-transparent rounded-none;
    }

    // Banner Wrapper
    .banner-wrapper {
        .swiper-wrapper {
            @apply pb-[52px];
        }

        .swiper-pagination {
            @apply text-left -ml-[5px];
        }

        .swiper-pagination-bullet {
            @apply w-8 h-2 rounded bg-[#9aa6af] opacity-40 duration-300 ease-linear;

            &.swiper-pagination-bullet-active {
                @apply w-[50px] opacity-100;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            @apply top-[calc(50%_-_26px)] w-[var(--swiper-navigation-button)] h-[var(--swiper-navigation-button)] rounded-full bg-white flex items-center justify-center;
        }

        .swiper-button-next {
            @apply shadow-[0_3px_6px_rgba(0,_0,_0,_0.16)] right-0;
        }

        .swiper-button-prev {
            @apply shadow-[0_-3px_6px_rgba(0,_0,_0,_0.16)] left-0;
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
            @apply text-base text-[#4b4b4b] font-bold ease-linear duration-300;
        }

        .swiper-button-next:hover:after,
        .swiper-button-prev:hover:after {
            @apply text-[#000];
        }

        .swiper-scrollbar {
            @apply hidden;
        }
    }

    // -webkit-tap-highlight-color
    .tap-highlight-color-transparent {
        -webkit-tap-highlight-color: transparent;
    }
}
